import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import schema from "../../validation/even-registeration-validation.js";
import "./event-registeration-form.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import getCookie from "../../utiles/Cookie.js";
import Joi from "joi";
import { Skeleton } from "@mui/material";
import toTitleCase from "../../utiles/toTitleCase.js";
const EventRegisteration = () => {
  const [successMsg, setsuccessMsg] = useState();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({});
  const [token, setToken] = useState("");
  const [registerLoading, setRegisterLoading] = useState(false);
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  useEffect(() => {
    const storedToken = user?.token;
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/profile`,
            {
              headers: {
                "Content-Type": "application/json",
                token: `Bearer ${token}`,
              },
            }
          );
          const data = response.data;
          setUserData(data);
        } catch (e) {
          console.error("Error fetching user data", e);
        }
      }
    };

    fetchUserData();
  }, [token]);

  const [eventFields, setEventFields] = useState([]);
  const [loading, setLoading] = useState(true);
  // Function to remove a row by index
  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };
  const [rows, setRows] = useState([
    { name: "", status: "", score: 0, attached_file: "" },
  ]);
  const handleAddRow = () => {
    setRows([...rows, { name: "", status: "", score: 0, attached_file: "" }]); // Add a new empty row to the array
  };
  const handleChangeExam = (index, field, value) => {
    if (field === "score") {
      value = parseInt(value);
    }
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };
  const [Inter_board, set_Inter_board] = useState(false);

  const renderField = (fieldConfig) => {
    const {
      field,
      required,
      type,
      enum_values,
      condition,
      label,
      placeholder,
    } = fieldConfig;
    const additionalProps = {};
    if (condition) {
      additionalProps["data-condition-field"] = condition.field;
      additionalProps["data-condition-value"] = condition.value;
    }
    const renderLabel = label || toTitleCase(field.replace(/_/g, " "));
    const renderPlaceholder =
      placeholder || `Enter ${field.replace(/_/g, " ")}`;
    switch (type) {
      case "enum":
        return (
          <Form.Group
            controlId={field}
            key={field}
            className="col-12 col-sm-4 mb-5"
          >
            <Form.Label>{renderLabel}</Form.Label>
            <Form.Control
              as="select"
              name={field}
              onChange={handleChange}
              required={required}
              {...additionalProps}
            >
              <option value="">{renderPlaceholder}</option>
              {enum_values.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Control>
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );

      case "file":
        return (
          <Form.Group
            controlId={field}
            className="col-12 col-sm-4 mb-5"
            key={field}
          >
            <Form.Label>{renderLabel}</Form.Label>
            <Form.Control
              type="file"
              name={field}
              onChange={handleChange}
              required={required}
              {...additionalProps}
            />
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );
      // Case for Array Input Type (International Board Acceptance)
      case "array":
        return (
          <>
            <div className="col-12">
              <div className="mb-4">
                <label className="form-label lable_text d-block">
                  International Exams
                </label>
                <span className="d-block">
                  Do you have any international exams?
                </span>
                <div className="d-flex align-items-center gap-4">
                  <label className="custom-radio">
                    <input
                      onChange={(e) => {
                        set_Inter_board(e.target.value);
                        setRows([
                          {
                            name: "",
                            status: "",
                            score: 0,
                            attached_file: "",
                          },
                        ]);
                      }}
                      className="form-check-input mx-2 "
                      name="checkinternational_board"
                      type="radio"
                      value="true"
                      required
                      id="yes_board"
                    />
                    <span>Yes</span>
                  </label>
                  <label className="custom-radio">
                    <input
                      onChange={(e) => set_Inter_board(e.target.value)}
                      className="form-check-input mx-2"
                      name="checkinternational_board"
                      type="radio"
                      required
                      id="No_board"
                      defaultChecked
                      value="false"
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Conditionally Rendered Rows */}
            <div className="col-md-12">
              {Inter_board === "true" || Inter_board == true ? (
                <>
                  {rows.map((row, index) => (
                    <Form.Group key={index} className="row mb-4">
                      <Form.Group
                        controlId={`exam_name${index}`}
                        className="col-md-2"
                      >
                        <Form.Label className="label_text">Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={row.name}
                          required
                          onChange={(e) =>
                            handleChangeExam(index, "name", e.target.value)
                          }
                          placeholder="Enter exam name"
                          className="input_text"
                        />
                      </Form.Group>
                      <Form.Group
                        controlId={`exam_score${index}`}
                        className="col-md-2"
                      >
                        <Form.Label className="label_text">Score</Form.Label>
                        <Form.Control
                          type="number"
                          value={row.score}
                          required
                          onChange={(e) =>
                            handleChangeExam(index, "score", e.target.value)
                          }
                          placeholder="Enter exam score"
                          className="input_text"
                        />
                      </Form.Group>
                      <Form.Group
                        controlId={`exam_status${index}`}
                        className="col-md-2"
                      >
                        <Form.Label className="label_text">
                          Pass/Fail
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={row.status}
                          required
                          onChange={(e) =>
                            handleChangeExam(index, "status", e.target.value)
                          }
                          className="input_text"
                        >
                          <option value="">Select Pass/Fail</option>
                          <option value="pass">Pass</option>
                          <option value="fail">Fail</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        controlId={`examFile${index}`}
                        className="col-md-4"
                      >
                        <Form.Label className="label_text">
                          Attach File
                        </Form.Label>
                        <div className="d-flex align-items-center gap-2 border rounded">
                          <label
                            role="button"
                            htmlFor={`examFile${index}`}
                            className="d-flex align-items-center gap-2"
                          >
                            <p
                              className="border h-100 m-0 py-1 px-3"
                              style={{ background: "#f8f9fa" }}
                            >
                              Select File
                            </p>
                            <span>
                              {row.attached_file?.name || "No file selected"}
                            </span>
                          </label>
                          <Form.Control
                            type="file"
                            accept=".pdf, image/*"
                            hidden
                            onChange={(e) => {
                              const maxSizeInBytes = 30 * 1024 * 1024;
                              if (e.target?.files[0]?.size <= maxSizeInBytes) {
                                handleChangeExam(
                                  index,
                                  "attached_file",
                                  e.target.files[0]
                                );
                              } else {
                                alert("Unsupported file format or size");
                                e.target.value = null;
                              }
                            }}
                          />
                        </div>
                      </Form.Group>
                      {index !== 0 && (
                        <div className="col-md-1 d-flex align-items-center">
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveRow(index)}
                            className="btn-sm"
                          >
                            Remove
                          </Button>
                        </div>
                      )}
                    </Form.Group>
                  ))}

                  <div className="btn_Add_div text-end">
                    <button
                      type="button"
                      className="btn_add rounded px-3"
                      onClick={handleAddRow}
                    >
                      +
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </>
        );

      case "number":
        return (
          <Form.Group
            controlId={field}
            key={field}
            className="col-12 col-sm-4 mb-5"
          >
            <Form.Label>{renderLabel}</Form.Label>
            <Form.Control
              type="number"
              name={field}
              onChange={handleChange}
              placeholder={renderPlaceholder}
              required={required}
              {...additionalProps}
            />
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );

      case "string":
        return (
          <Form.Group
            controlId={field}
            key={field}
            className="col-12 col-sm-4 mb-5"
          >
            <Form.Label>{renderLabel}</Form.Label>
            <Form.Control
              type="text"
              name={field}
              onChange={handleChange}
              placeholder={renderPlaceholder}
              required={required}
              {...additionalProps}
            />
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );

      default:
        return null;
    }
  };
  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          ` ${process.env.REACT_APP_API_URL}/event/active`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setsuccessMsg(response.data.result.successMsg || "noFound");
        setEventFields(response.data.result.requiredFields);
        console.log(
          response.data.result.successMsg,
          "response.data.result.successMsg"
        );
      } catch (e) {
        console.error("Error fetching event data", e);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventFields == null, successMsg == null, token]);
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (e.target.style) {
      if (value == null || value === "" || value === undefined) {
        e.target.style.border = "2px solid red";
      } else {
        e.target.style.border = "none";
      }
    }

    if (type === "file") {
      const file = files[0];
      const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
      const allowedTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/webp",
      ];
      if (file) {
        if (!allowedTypes.includes(file.type)) {
          alert("Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed.");
          e.target.value = null;
          return;
        }
        if (file.size > maxSizeInBytes) {
          alert("Max size 1 MB.");
          e.target.value = null;
          return;
        }

        setFormData((prevData) => ({
          ...prevData,
          [name]: file,
        }));

        if (schema.$_terms.keys.find((key) => key.key === name)) {
          const fieldSchema = Joi.object({ [name]: schema.extract(name) });
          const { error } = fieldSchema.validate({ [name]: file });
          setErrors({
            ...errors,
            [name]: error ? error.details[0].message : "",
          });
        } else {
          const updatedErrors = { ...errors };
          delete updatedErrors[name];
          setErrors(updatedErrors);
        }
      } else {
        e.target.value = null;
      }
    } else if (type == "number") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: Number(value),
      }));

      if (schema.$_terms.keys.find((key) => key.key === name)) {
        const fieldSchema = Joi.object({ [name]: schema.extract(name) });
        const { error } = fieldSchema.validate({ [name]: value });
        setErrors({ ...errors, [name]: error ? error.details[0].message : "" });
      } else {
        const updatedErrors = { ...errors };
        delete updatedErrors[name];
        setErrors(updatedErrors);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (schema.$_terms.keys.find((key) => key.key === name)) {
        const fieldSchema = Joi.object({ [name]: schema.extract(name) });
        const { error } = fieldSchema.validate({ [name]: value });
        setErrors({ ...errors, [name]: error ? error.details[0].message : "" });
      } else {
        const updatedErrors = { ...errors };
        delete updatedErrors[name];
        setErrors(updatedErrors);
      }
    }
  };

  const [success, setsuccess] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setRegisterLoading(true);
    const filteredFormData = Object.keys(formData).reduce((acc, key) => {
      if (schema.$_terms.keys.find((field) => field.key === key)) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});
    const { error } = schema.validate(filteredFormData, { abortEarly: false });
    if (error) {
      const validationErrors = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      setRegisterLoading(false);
    } else {
      const formDataToSend = new FormData();
      const exam = eventFields.some((e) => {
        return e.field == "international_exams";
      });
      if (exam) {
        if (rows.length > 0) {
          rows.forEach((exam, index) => {
            // Append exam data
            formDataToSend.append(
              `international_exams[${index}][name]`,
              exam.name
            );
            formDataToSend.append(
              `international_exams[${index}][status]`,
              exam.status
            );
            formDataToSend.append(
              `international_exams[${index}][score]`,
              exam.score
            );
            if (exam.attached_file && exam.attached_file instanceof File) {
              formDataToSend.append(
                `international_exams[${index}][attached_file]`,
                exam.attached_file
              );
            }
          });
        } else {
          formDataToSend.append(`international_exams`, []);
        }
      }
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          formDataToSend.append(key, formData[key]);
        }
      }
      fetch(`${process.env.REACT_APP_API_URL}/application`, {
        method: "POST",
        redirect: "follow",
        body: formDataToSend,
        headers: {
          token: `Bearer ${token}`,
          Accept: "*/*",
        },
      })
        .then((res) =>
          res.json().then((data) => ({
            ...data,
            status: res.status,
            ok: res.ok,
          }))
        )
        .then((result) => {
          if (result.ok) {
            setsuccess(true);
          } else {
            console.error(
              `Request failed with status ${result.status}:`,
              result
            );
            alert(
              result.message ||
                result.errors[0].msg ||
                result.error.message ||
                result.error
            );
            // if (result.status === 400) {
            //   alert(result.message || "Validation error occurred. Please check your input.");
            // } else if (result.status === 401) {
            //   alert("You are not authorized. Please log in first.");
            // } else {
            //   alert("An error occurred while processing your request. Please try again.");
            // }
          }
        })
        .catch((err) => {
          console.error("Network or server error:", err);
          alert(
            "An unexpected error occurred. Please check your connection and try again."
          );
        })
        .finally(() => setRegisterLoading(false));
    }
  };

  const navigate = useHistory();

  return (
    <>
      {success === true ? (
        <div className="success_message p-5 text-center my-4">
          <h3>You Have been Registered Successfully!</h3>
          <div className="success_message_text  text-center px-5 d-flex justify-content-center">
            <p
              className=" p-3 text-center mx-5 w-50 "
              style={{ fontWeight: "500", lineHeight: "35px" }}
            >
              {successMsg !== "noFound" && successMsg ? (
                successMsg
              ) : successMsg == "noFound" ? (
                " Dear Applicant Thank you for applying to the Mental health Conference. This message serves as a receipt confirming that we have successfully received your application. A confirmation email with further details will be sent to you. Please ensure to check your email regularly for updates. We look forward to welcoming you to the Mental health Conference ."
              ) : (
                <>
                  <Skeleton style={{ width: "100%", height: "30px" }} />
                  <Skeleton style={{ width: "100%", height: "30px" }} />
                  <Skeleton style={{ width: "100%", height: "30px" }} />
                </>
              )}
            </p>
          </div>

          <button className="Home_back" onClick={() => navigate.push("/")}>
            Home
          </button>
        </div>
      ) : (
        <>
          <div className="header"></div>
          <h1 className="heading-text text-center mt-5 mb-0">Registration</h1>
          <div className="primary-info row my-3 mx-0 mx-lg-5">
            <p className="primary-info__heading fs-4">Personal Information</p>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="firstName">
                First Name
              </label>
              <div id="firstName">{userData.user?.first_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="middleName">
                Middle Name
              </label>
              <div id="middleName">{userData.user?.middle_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="lastName">
                Last Name
              </label>
              <div id="lastName">{userData.user?.last_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="gender">
                Gender
              </label>
              <div id="gender">{userData.user?.gender || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5  ">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <div id="email">{userData.user?.email || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="mobileNumber">
                Mobile Number
              </label>
              <div id="mobileNumber">
                {userData.user?.mobile_number || "N/A"}
              </div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="nationalId">
                National Id
              </label>
              <div id="nationalId">{userData.user?.national_id || "N/A"}</div>
            </div>
          </div>
          {loading ? (
            <div className="column">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          ) : (
            <Form onSubmit={handleSubmit} className="mt-3 mx-0 mx-lg-5">
              <div className="row mx-1">
                {eventFields
                  .filter(
                    (field) =>
                      (field?.condition?.field === "" || !field?.condition) &&
                      !field?.field?.toLowerCase().includes("sml")
                  )
                  .map(renderField)}
              </div>
              <div className="row mx-1">
                {eventFields
                  .filter((field) => {
                    const conditionField =
                      field?.condition?.field.toLowerCase();
                    const conditionValue =
                      field?.condition?.value.toLowerCase();
                    return (
                      conditionField &&
                      formData[conditionField]?.toLowerCase() === conditionValue
                    );
                  })
                  .map(renderField)}
              </div>
              <div className="w-100 d-flex justify-content-center my-5">
                <Button
                  type="submit"
                  style={{
                    backgroundColor: registerLoading ? "gray" : "",
                    cursor: registerLoading ? "not-allowed" : "",
                    pointerEvents: registerLoading ? "none" : "auto",
                  }}
                  disabled={registerLoading}
                >
                  {registerLoading ? "Registering..." : "REGISTER"}
                </Button>
              </div>
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default EventRegisteration;
